import Link from "next/link";

import { CSF_URL } from "@ctv/app/[locale]/_components/pre-login/constants";
import { LocalizedLink, useContentResource } from "@ctv/core";
import { ButtonSeo, Image, TextSeo, Token, ViewSeo } from "@ctv/momentum";
import DividerSeo from "@ctv/momentum/Divider/DividerSeo";

import MailIcon from "@traveloka/icon-kit-web/react/IcContactMail";
import PhoneIcon from "@traveloka/icon-kit-web/react/IcContactPhone";
import IcSystemArrowRight12 from "@traveloka/icon-kit-web/react/IcSystemArrowRight12";

import styles from "./Footer.module.css";

export default function Footer() {
  const cr = useContentResource().CorporateRevampFooter;

  return (
    <ViewSeo className={styles.container}>
      <ViewSeo spacing="l">
        <ViewSeo className={styles.footerInfoContainer} spacing="xl">
          <ViewSeo spacing="s">
            <TextSeo variant="title-2" ink="white-primary">
              {cr.ourOfficeText}
            </TextSeo>
            <TextSeo ink="white-secondary" variant="ui-small">
              {cr.addressText}
            </TextSeo>
            <Link
              className={styles.link}
              href={"https://goo.gl/maps/SFSiT7TbhZUCaTzV9"}
              target="_blank"
            >
              <ButtonSeo
                className={styles.button}
                size="small"
                variant="text-white"
                text={cr.getDirectionText}
                endIcon={
                  <IcSystemArrowRight12
                    color={Token.color.lightPrimary}
                    width={12}
                    height={12}
                  />
                }
              />
            </Link>
          </ViewSeo>

          <ViewSeo spacing="s">
            <TextSeo variant="title-2" ink="white-primary">
              {cr.ourServiceText}
            </TextSeo>
            <Link className={styles.link} href={CSF_URL} target="_blank">
              <TextSeo ink="white-secondary" variant="ui-small">
                {cr.financeBusinessService}
              </TextSeo>
            </Link>
          </ViewSeo>

          <ViewSeo spacing="s">
            <TextSeo variant="title-2" ink="white-primary">
              {cr.othersText}
            </TextSeo>
            <LocalizedLink
              className={styles.link}
              href="/terms-and-conditions"
              target="_blank"
            >
              <TextSeo ink="white-secondary" variant="ui-small">
                {cr.termsAndConditionsText}
              </TextSeo>
            </LocalizedLink>
          </ViewSeo>

          <ViewSeo spacing="s">
            <TextSeo variant="title-2" ink="white-primary">
              {cr.needHelpText}
            </TextSeo>
            <ViewSeo row spacing="xs" align="center">
              <PhoneIcon
                color={Token.color.lightSecondary}
                accentColor={Token.color.lightSecondary}
                width={20}
                height={20}
              />
              <a className={styles.link} href={`tel:${cr.companyPhoneNumber}`}>
                <TextSeo variant="ui-small" ink="white-secondary">
                  {cr.companyPhoneNumber}
                </TextSeo>
              </a>
            </ViewSeo>
            <ViewSeo row spacing="xs" align="center">
              <MailIcon
                color={Token.color.lightSecondary}
                accentColor={Token.color.lightSecondary}
                width={20}
                height={20}
              />
              <a className={styles.link} href={`mailto:${cr.companyEmail}`}>
                <TextSeo variant="ui-small" ink="white-secondary">
                  {cr.companyEmail}
                </TextSeo>
              </a>
            </ViewSeo>
          </ViewSeo>
        </ViewSeo>

        <DividerSeo />
        <Image
          className={styles.logo}
          src="/images/traveloka-for-corporates-white.png"
          alt="tvlk white logo"
          height={30}
          width={231}
        />
        <TextSeo variant="ui-tiny" ink="white-primary">
          {cr.copyrightText}
        </TextSeo>
      </ViewSeo>
    </ViewSeo>
  );
}
